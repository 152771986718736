import cloneDeep from 'lodash/cloneDeep';
import { createWithEqualityFn } from 'zustand/traditional';

import enLocale from '../../locale/en.json';

import localesSchema from './schema';

const defaultLocale = enLocale as LokaliseData;

interface LokaliseData {
  [s: string]: string;
}

interface PartialLocales {
  [s: string]: string | PartialLocales;
}

export enum Locale {
  En = 'en',
  Da = 'da',
  Es = 'es_ES',
  Sv = 'sv',
}

export const locales: Locale[] = [
  Locale.Da,
  Locale.En,
  Locale.Es,
  Locale.Sv,
];

export const localeNames: { [s: string]: string } = {
  [Locale.Da]: 'Dansk',
  [Locale.En]: 'English',
  [Locale.Es]: 'Español',
  [Locale.Sv]: 'Svenska',
};

export type LocalesSchema = typeof localesSchema;

interface LocalesState {
  locale: Locale;
  locales: LocalesSchema;
  loaded: boolean;
  loading: boolean;
  setLocale: (locale: Locale) => Promise<void>;
}

function processTranslations(lokaliseData: PartialLocales): LocalesSchema {
  const iterateTranslationObject = (object: PartialLocales) => {
    const newObject = { ...object };
    for (const key of Object.keys(object)) {
      const item = object[key];
      if (typeof item === 'string') {
        newObject[key] = lokaliseData[item] || defaultLocale[item] || newObject[key];
      } else if (typeof item === 'object') {
        newObject[key] = iterateTranslationObject(item);
      }
    }
    return newObject;
  };
  return iterateTranslationObject(cloneDeep(localesSchema as PartialLocales)) as LocalesSchema;
}

function getLocale(): Locale {
  return localStorage.getItem('locale') as (Locale | null) || Locale.En;
}

function saveLocale(locale: Locale) {
  return localStorage.setItem('locale', locale);
}

export const useLocalesStore = createWithEqualityFn<LocalesState>((set) => ({
  locale: getLocale(),
  locales: localesSchema,
  loaded: false,
  loading: false,
  async setLocale(locale: Locale) {
    set((state) => ({
      ...state,
      locale,
      loading: true,
    }));
    const data = (await import(`../../locale/${locale}.json`) as { default: LokaliseData }).default;
    const translations = processTranslations(data);
    set((state) => ({
      ...state,
      locales: translations,
      loading: false,
      loaded: true,
    }));
    saveLocale(locale);
  },
}));

const useLocales = () => useLocalesStore((state) => state.locales);

export default useLocales;
