import React, {
  FC, useCallback, useEffect, useMemo,
  useState,
} from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { ClIcon } from '@enreach/core-component-library-react';
import classNames from 'classnames';
import { shallow } from 'zustand/shallow';

import { useStore } from '@store';

import s from './SideMenu.module.scss';

interface MainItem {
  to: string
  content: string
  icon: string
}

interface Item {
  to: string
  content: string
}

interface ItemWithSubmenu {
  path: string
  content: string
  icon: string
  submenu: Item[]
}

const links: (MainItem | ItemWithSubmenu)[] = [
  {
    to: '/',
    content: 'Dashboard',
    icon: 'generic--home',
  },
  {
    content: 'Leads',
    path: 'Leads',
    icon: 'generic--user--user--alt',
    submenu: [
      {
        to: 'Overview',
        content: 'Overview',
      },
      {
        to: 'Stoplist',
        content: 'Stoplist',
      },
      {
        to: 'LeadSources',
        content: 'Lead Sources',
      },
      {
        to: 'LifeCycle',
        content: 'LifeCycle',
      },
    ],
  },
  {
    content: 'Segmentation',
    path: 'Segmentation',
    icon: 'generic--layers',
    submenu: [
      {
        to: 'Segmentation',
        content: 'Segmentation',
      },
      {
        to: 'Goals',
        content: 'Goals',
      },
    ],
  },
  {
    content: 'Activities',
    path: 'Activities',
    icon: 'generic--activity',
    submenu: [
      {
        to: 'Telemarketing',
        content: 'Telemarketing',
      },
      {
        to: 'Email',
        content: 'Email',
      },
      {
        to: 'Sms',
        content: 'Sms',
      },
      {
        to: 'Webtracking',
        content: 'Webtracking',
      },
      {
        to: 'OrderConfirmation',
        content: 'Order Confirmation',
      },
      {
        to: 'FacebookAudienceList',
        content: 'Facebook Audience List',
      },
      {
        to: 'Popup',
        content: 'Popup',
      },
      {
        to: 'ExportList',
        content: 'Export List',
      },
      {
        to: 'PdfContract',
        content: 'PDF Contract',
      },
    ],
  },
  {
    to: 'Automation',
    content: 'Automation',
    icon: 'communication--channel',
  },
  {
    content: 'Reporting',
    path: 'Reporting',
    icon: 'generic--chart--alt',
    submenu: [
      {
        to: 'Email',
        content: 'Email',
      },
      {
        to: 'SMS',
        content: 'SMS',
      },
      {
        to: 'Leads',
        content: 'Leads',
      },
      {
        to: 'Telemarketing',
        content: 'Telemarketing',
      },
      {
        to: 'Webtracking',
        content: 'Webtracking',
      },
      {
        to: 'Goal',
        content: 'Goal',
      },
      {
        to: 'ConversionTracking',
        content: 'Conversion Tracking',
      },
      {
        to: 'Automation',
        content: 'Automation',
      },
      {
        to: 'OrderConfirmation',
        content: 'Order Confirmation',
      },
    ],
  },
  {
    to: 'Administration',
    content: 'Administration',
    icon: 'action--settings',
  },
];

const isItemWithSubmenu = (o:Item | ItemWithSubmenu): o is ItemWithSubmenu => (
  Boolean((o as ItemWithSubmenu).submenu)
);

const SideMenu: FC = () => {
  const location = useLocation();

  const [sideMenuOpen, set] = useStore(
    (state) => [state.sideMenuOpen, state.set],
    shallow,
  );

  const [expanded, setExpanded] = useState(false);
  const [openedPath, setOpenedPath] = useState<string>('');

  const getOpenedPath = useCallback(
    () => {
      setOpenedPath((location.pathname.split('/')[1] || '').toLowerCase());
    },
    [location.pathname],
  );

  useEffect(
    () => {
      getOpenedPath();
    },
    [getOpenedPath],
  );

  const composedExpanded = useMemo(
    () => sideMenuOpen || expanded,
    [expanded, sideMenuOpen],
  );

  const toggleExpanded = useCallback(
    () => {
      const newState = !composedExpanded;

      set({
        sideMenuOpen: newState,
      });

      setExpanded(newState);
    },
    [composedExpanded, set],
  );

  const toggleSubmenu = useCallback(
    (path: string) => {
      set({
        sideMenuOpen: true,
      });

      setExpanded(true);

      setOpenedPath(path === openedPath ? '' : path.toLowerCase());
    },
    [openedPath, set],
  );

  useEffect(() => {
    const handleDocumentClick = ({ target }: MouseEvent) => {
      if (target && !(target as HTMLElement).closest(`.${s.root}`)) {
        setExpanded(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <CSSTransition
      in={composedExpanded}
      timeout={250}
      classNames={{
        enterActive: `${s.expanded} ${s.enterActive}`,
        enterDone: `${s.expanded} ${s.enterDone}`,
        exitActive: `${s.expanded} ${s.exitActive}`,
        exitDone: '',
      }}
      onExited={getOpenedPath}
    >
      <div className={classNames(s.root, {
        [s.expanded]: composedExpanded,
      })}
      >
        <div className={s.in}>
          <div className={s.header}>
            <button type="button" onClick={toggleExpanded} className={s.toggleButton}>
              <ClIcon className={s.icon} name="navigation--menu" />
            </button>
          </div>

          <div className={s.content}>
            {links.map((o) => {
              if (isItemWithSubmenu(o)) {
                return (
                  <React.Fragment key={o.content}>
                    <button
                      type="button"
                      onClick={() => toggleSubmenu(o.path.toLowerCase())}
                      className={classNames(s.item, {
                        [s.active]: o.path.toLowerCase() === openedPath,
                      })}
                    >
                      <ClIcon className={s.icon} name={o.icon} />
                      <span className={s.itemContent}>{o.content}</span>
                      <ClIcon className={s.arrow} name="navigation--chevron--down" />
                    </button>
                    <div className={s.submenu}>
                      {o.submenu.map((so) => (
                        <NavLink
                          key={so.to}
                          className={({ isActive }) => classNames(s.subitem, {
                            [s.active]: isActive,
                          })}
                          to={`${o.path}/${so.to}`}
                        >
                          {so.content}
                        </NavLink>
                      ))}
                    </div>
                  </React.Fragment>
                );
              }

              return (
                <NavLink
                  key={o.to}
                  className={({ isActive }) => classNames(s.item, {
                    [s.active]: isActive,
                  })}
                  to={o.to}
                >
                  <ClIcon className={s.icon} name={o.icon} />
                  <span className={s.itemContent}>{o.content}</span>
                </NavLink>
              );
            })}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default SideMenu;
