import { createWithEqualityFn } from 'zustand/traditional';

export interface User {
  token: string | null;
}

interface State {
  user: User;
  sideMenuOpen: boolean;
  pageLoaderNode?: HTMLDivElement;
}

interface StateWithActions extends State {
  setUser: (user: User) => void;
  set: (state: Partial<State>) => void;
}

export const useStore = createWithEqualityFn<StateWithActions>((set) => ({
  user: {
    token: localStorage.getItem('userToken'),
  },
  sideMenuOpen: false,
  setUser: (user) => {
    set({ user });
  },
  set: (newState) => {
    set((state) => ({
      ...state,
      ...newState,
    }));
  },
}));

export default useStore;
