export enum Sections {
  Dashboard = 'Dashboard',
  Login = 'Login',
  Activities = 'Activities',
}

export enum Pages {
  ExportList = 'ExportList',
  Sms = 'Sms',
  FacebookAudienceList = 'FacebookAudienceList',
  PdfContract = 'PdfContract',
  WebTracking = 'WebTracking',
}

export enum SubPages {
  Edit = 'Edit',
  Copy = 'Copy',
  New = 'New',
  Preview = 'Preview',
}

export function getRouteSection(section: Sections): string {
  return `/${section}`;
}

export default function getRoutePage(
  section: Sections,
  page: Pages,
  ...urlParams: string[]
): string {
  const urlParamsString = urlParams.length > 0 ? `/${urlParams.join('/')}` : '';
  return `${getRouteSection(section)}/${page}${urlParamsString}`;
}

export function getRoute(
  section: Sections,
  page: Pages,
) {
  return (...urlParams: string[]) => getRoutePage(section, page, ...urlParams);
}
