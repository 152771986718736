import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from 'axios';

import store from '@store';

export interface ApiResult<Entity> {
  isSuccess: boolean;
  result: Entity;
}

const instanceConfig: AxiosRequestConfig = {
  baseURL: import.meta.env.DEV ? '/backend' : '/api',
};

const client: AxiosInstance = axios.create(instanceConfig);

client.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const {
    user: {
      token,
    },
  } = store.getState();
  const newConfig: InternalAxiosRequestConfig = config;
  if (token && newConfig.headers) {
    newConfig.headers.Authorization = `Bearer ${token}`;
  }
  return newConfig;
});

export default client;

export * from 'axios';
