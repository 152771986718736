import {
  defineCustomElementClAccordion,
  defineCustomElementClButton,
  defineCustomElementClColorPicker,
  defineCustomElementClDataTable,
  defineCustomElementClDataTableBody,
  defineCustomElementClDataTableCell,
  defineCustomElementClDataTableHead,
  defineCustomElementClDataTableHeadCell,
  defineCustomElementClDataTableHeadRow,
  defineCustomElementClDataTableRow,
  defineCustomElementClDataTableRowCollapse,
  defineCustomElementClDatePicker,
  defineCustomElementClDialog,
  defineCustomElementClDivider,
  defineCustomElementClDropzone,
  defineCustomElementClField,
  defineCustomElementClGrid,
  defineCustomElementClIcon,
  defineCustomElementClIconButton,
  defineCustomElementClLightbox,
  defineCustomElementClLoader,
  defineCustomElementClPagination,
  defineCustomElementClPictogram,
  defineCustomElementClSelectField,
  defineCustomElementClTab,
  defineCustomElementClTabs,
  defineCustomElementClTextField,
  defineCustomElementClToast,
  defineCustomElementClTooltip,
  defineCustomElementClTypography,
  defineCustomElementClRadio,
  defineCustomElementClCheckbox,
} from '@enreach/core-component-library/dist/components/index';

defineCustomElementClButton();
defineCustomElementClDialog();
defineCustomElementClDivider();
defineCustomElementClGrid();
defineCustomElementClIconButton();
defineCustomElementClIcon();
defineCustomElementClLightbox();
defineCustomElementClLoader();
defineCustomElementClPictogram();
defineCustomElementClTooltip();
defineCustomElementClToast();
defineCustomElementClTypography();
defineCustomElementClTextField();
defineCustomElementClDataTable();
defineCustomElementClDataTableBody();
defineCustomElementClDataTableCell();
defineCustomElementClDataTableHead();
defineCustomElementClDataTableHeadCell();
defineCustomElementClDataTableRow();
defineCustomElementClDataTableHeadRow();
defineCustomElementClDataTableRowCollapse();
defineCustomElementClDatePicker();
defineCustomElementClField();
defineCustomElementClDropzone();
defineCustomElementClTabs();
defineCustomElementClTab();
defineCustomElementClAccordion();
defineCustomElementClColorPicker();
defineCustomElementClPagination();
defineCustomElementClSelectField();
defineCustomElementClRadio();
defineCustomElementClCheckbox();
