import {
  ReactNode,
  Suspense,
} from 'react';

import PageLoader from '@components/PageLoader';

interface PageSuspenseProps {
  children?: ReactNode;
}

export default function PageSuspense({
  children,
}: PageSuspenseProps) {
  return (
    <Suspense fallback={<PageLoader />}>
      {children}
    </Suspense>
  );
}
