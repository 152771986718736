import { ComponentProps, ReactNode } from 'react';
import { Path, PathValue, useFormContext } from 'react-hook-form';
import {
  ClTextField,
} from '@enreach/core-component-library-react';
import omit from 'lodash/omit';

type ClTextFieldProps = ComponentProps<typeof ClTextField>;

interface TextFieldProps<T extends object> extends ClTextFieldProps {
  name: Path<T>;
  children?: ReactNode;
}

export default function TextField<T extends object>({
  name,
  children,
  ...rest
}: TextFieldProps<T>) {
  const {
    register,
    setValue,
    getFieldState,
  } = useFormContext<T>();
  return (
    <ClTextField
      {...rest}
      {...omit(register(name), 'min', 'max')}
      onClInput={(e) => {
        setValue(name, e.detail.value as PathValue<T, Path<T>>, { shouldValidate: true });
      }}
      error={getFieldState(name).invalid}
    >
      {children}
    </ClTextField>
  );
}
