import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';

import { useStore } from '@store';

import Menu from '../SideMenu';
import TopMenu from '../TopMenu';

import s from './PageLayout.module.scss';

const PageLayout: FC = () => {
  const sideMenuOpen = useStore((state) => state.sideMenuOpen);

  return (
    <>
      <Menu />

      <TopMenu />

      <div
        className={classNames(s.container, {
          [s.sideMenuOpen]: sideMenuOpen,
        })}
      >
        <Outlet />
      </div>
    </>
  );
};

export default PageLayout;
