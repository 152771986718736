import { FC, lazy } from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom';

import NoMatch from '@routes/NoMatch';

import PageSuspense from '@components/PageSuspense';

import { Pages } from '@utils/routes';

const ExportList = lazy(() => import('./ExportList'));
const SMS = lazy(() => import('./SMS'));
const FacebookAudienceList = lazy(() => import('./FacebookAudienceList'));
const PDFContract = lazy(() => import('./PDFContract'));
const WebTracking = lazy(() => import('./WebTracking'));

const Leads: FC = () => (
  <PageSuspense>
    <Routes>
      <Route path="" element={<div>Hello</div>} />
      <Route path={`${Pages.ExportList}/*`} element={<ExportList />} />
      <Route path={`${Pages.Sms}/*`} element={<SMS />} />
      <Route path={`${Pages.FacebookAudienceList}/*`} element={<FacebookAudienceList />} />
      <Route path={`${Pages.PdfContract}/*`} element={<PDFContract />} />
      <Route path={`${Pages.WebTracking}/*`} element={<WebTracking />} />

      <Route path="*" element={<NoMatch />} />
    </Routes>
  </PageSuspense>
);

export default Leads;
