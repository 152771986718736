export default {
  general: {
    cancel: 'cancel',
    confirm: 'confirm',
    error: 'Error',
    loading: 'Loading',
    noResults: 'NoResults',
    searchPlaceholder: 'SearchPlaceholder',
    generalSettings: 'GeneralSettings',
  },
  notifications: {
    savingSuccessTitle: 'Notification.SavingSuccess.Title',
    savingSuccessMessage: 'Notification.SavingSuccess.Message',
  },
  confirm: {
    deleteTitle: 'Confirm.Delete.Title',
    deleteMessage: 'Confirm.Delete.Message',
  },
  button: {
    new: 'Button.New',
    edit: 'Button.Edit',
    copy: 'Button.Copy',
    export: 'Button.Export',
    delete: 'Button.Delete',
    save: 'Button.Save',
    cancel: 'Button.Cancel',
    yesDelete: 'Button.YesDelete',
    send: 'Button.Send',
    sendNow: 'Button.SendNow',
    sendLater: 'Button.SendLater',
    addToSms: 'Button.AddToSms',
    addToLink: 'Button.AddToLink',
    preview: 'Button.Preview',
    viewContract: 'Button.ViewContract',
    uploadPdfFile: 'Button.UploadPdfFile',
    uploadFileDescription: 'Button.UploadFile.Description',
    settings: 'Button.Settings',
    uploadFont: 'Button.UploadFont',
    addFont: 'Button.AddFont',
    add: 'Button.Add',
    ok: 'Button.Ok',
    yes: 'Button.Yes',
    no: 'Button.No',
  },
  fields: {
    fileName: 'Fields.FileName',
    fileNamePlaceholder: 'Fields.FileName.Placeholder',
    fileFormat: 'Fields.FileFormat',
    fileLineBreak: 'Fields.FileLineBreak',
    tags: 'Fields.Tags',
    mergeFields: 'Fields.MergeFields',
    name: 'Fields.Name',
    namePlaceholder: 'Fields.Name.Placeholder',
    sendTo: 'Fields.SendTo',
    from: 'Fields.From',
    to: 'Fields.To',
    message: 'Fields.Message',
    segment: 'Fields.Segment',
    phoneNumber: 'Fields.PhoneNumber',
    phoneNumberPlaceholder: 'Fields.PhoneNumber.Placeholder',
    time: 'Fields.Time',
    date: 'Fields.Date',
    total: 'Fields.Total',
    description: 'Fields.Description',
    createdAt: 'Fields.CreatedAt',
    updatedAt: 'Fields.UpdatedAt',
    timestamp: 'Fields.Timestamp',
    customerId: 'Fields.CustomerId',
    ipAddress: 'Fields.IpAddress',
    dateTime: 'Fields.DateTime',
    signature: 'Fields.Signature',
    fontFamily: 'Fields.FontFamily',
    fontUrl: 'Fields.FontUrl',
    fontSize: 'Fields.FontSize',
    fontWeight: 'Fields.FontWeight',
    textAlign: 'Fields.TextAlign',
    color: 'Fields.Color',
    sendFrom: 'Fields.SendFrom',
    mfaType: 'Fields.MfaType',
    header: 'Fields.Header',
    body: 'Fields.Body',
    Lead: 'Fields.Lead',
    pageVisitsCount: 'Fields.PageVisitsCount',
  },
  topMenu: {
    searchPlaceholder: 'TopMenu.SearchPlaceholder',
    description: 'Header.Description',
  },
  pagination: {
    info: 'Pagination.Info',
  },
  validation: {
    fieldRequired: 'Validation.FieldRequired',
    nameIsAlreadyExists: 'Validation.NameIsAlreadyExists',
    fileNameFormat: 'Validation.FileNameFormat',
    fromNameFormat: 'Validation.FromNameFormat',
  },
  sections: {
    Dashboard: 'Sections.Dashboard',
    Login: 'Sections.Login',
    Activities: 'Sections.Activities',
  },
  pages: {
    Sms: 'Pages.Sms',
    ExportList: 'Pages.ExportList',
    FacebookAudienceList: 'Pages.FacebookAudienceList',
    PdfContract: 'Pages.PdfContract',
    WebTracking: 'Pages.WebTracking',
  },
  subPages: {
    Edit: 'SubPages.Edit',
    Copy: 'SubPages.Copy',
    New: 'SubPages.New',
    Preview: 'SubPages.Preview',
  },
  activities: {
    sms: {
      title: 'Activities.SMS.Title',
      subtitle: 'Activities.SMS.Subtitle',
      // title: '', (?)
      search: {
        placeholder: 'Activities.SMS.Search.PlaceHolder',
      },
      newSms: {
        btn: {
          label: 'Activities.SMS.NewSMS.Btn.Label',
        },
      },
      copySms: {
        btn: {
          label: 'Activities.SMS.CopySMS.Btn.Label',
        },
      },
      smsEdit: {
        actions: {
          delete: {
            label: 'Activities.SMS.SMSEdit.Actions.Delete.Label',
          },
        },
        delete: {
          warning: {
            text: 'Activities.SMS.SMSEdit.Delete.Warning.Text',
            Cancel: {
              label: 'Activities.SMS.SMSEdit.Delete.Warning.Cancel.Label',
            },
            confirm: {
              label: 'Activities.SMS.SMSEdit.Delete.Warning.Confirm.Label',
            },
          },
        },
      },
      numberOfCharacters: 'Activities.Sms.Message.NumberOfCharacters',
      limitSendingHours: 'Activities.Sms.Message.LimitSendingHours',
      insertMergeFields: 'Activities.Sms.Message.InsertMergeFields',
      insertLinkInSms: 'Activities.Sms.Message.InsertLinkInSms',
      insertLinkInSmsPlaceholder: 'Activities.Sms.Message.InsertLinkInSms.Placeholder',
      insertContractLinkInSms: 'Activities.Sms.Message.InsertContractLinkInSms',
      sendDetails: 'Activities.Sms.Message.Modal.SendDetails.Title',
      sendDetailsDescription: 'Activities.Sms.Message.Modal.SendDetails.Description',
      segmentLeadCountMessage: 'Activities.Sms.SegmentLeadCount.Message',
    },
    smsInfo: {
      title: 'Activities.SMSInfo.Title',
      activities: {
        subtitle: 'Activities.SMSInfo.Activities.SubTitle',
      },
      sms: {
        subtitle: 'Activities.SMSInfo.SMS.SubTitle',
      },
      smsInfo: {
        subtitle: 'Activities.SMSInfo.SMSInfo.SubTitle',
      },
      option: {
        title: 'Activities.SMSInfo.Option.Title',
        sendNow: {
          label: 'Activities.SMSInfo.Option.SendNow.Label',
          smsName: {
            title: 'Activities.SMSInfo.Option.SendNow.SMSName.Title',
            header: 'Activities.SMSInfo.Option.SendNow.SMSName.Header',
            placeholder: 'Activities.SMSInfo.Option.SendNow.SMSName.Placeholder',
          },
          executeClose: {
            btn: {
              label: 'Activities.SMSInfo.Option.SendNow.ExecuteClose.Btn.Label',
            },
          },
          executeSave: {
            btn: {
              label: 'Activities.SMSInfo.Option.SendNow.ExecuteSave.Btn.Label',
            },
          },
        },
        sendLater: {
          label: 'Activities.SMSInfo.Option.SendLater.Label',
          smsName: {
            title: 'Activities.SMSInfo.Option.SendLater.SMSName.Title',
            placeholder: 'Activities.SMSInfo.Option.SendLater.SMSName.Placeholder',
          },
          executeClose: {
            btn: {
              label: 'Activities.SMSInfo.Option.SendLater.ExecuteClose.Btn.Label',
            },
          },
          executeSave: {
            btn: {
              label: 'Activities.SMSInfo.Option.SendLater.ExecuteSave.Btn.Label',
            },
          },
        },
      },
      saveSMS: {
        label: 'Activities.SMSInfo.SaveSMS.Label',
        smsName: {
          title: 'Activities.SMSInfo.SaveSMS.SMSName.Title',
          header: 'Activities.SMSInfo.SaveSMS.SMSName.Header',
          placeholder: 'Activities.SMSInfo.SaveSMS.SMSName.Placeholder',
        },
        executeClose: {
          btn: {
            label: 'Activities.SMSInfo.SaveSMS.ExecuteClose.Btn.Label',
          },
        },
        executeSave: {
          btn: {
            label: 'Activities.SMSInfo.SaveSMS.ExecuteSave.Btn.Label',
          },
        },
      },
      stdField: {
        sentTo: 'Activities.SMSInfo.StdField.SendTo',
        from: 'Activities.SMSInfo.StdField.From',
        tags: 'Activities.SMSInfo.StdField.Tags',
        limitSendingHours: 'Activities.SMSInfo.StdField.LimitSendingHours',
        to: 'Activities.SMSInfo.StdField.To',
        insertaMergefield: 'Activities.SMSInfo.StdField.InsertaMergefield',
        insertLink: 'Activities.SMSInfo.StdField.InsertLink',
        AddtoSMS: 'Activities.SMSInfo.StdField.AddtoSMS',
      },
      executeAddtoSMS: {
        btn: {
          label: 'Activities.SMSInfo.ExecuteAddtoSMS.Btn.Label',
        },
      },
      executeAddtoLink: {
        btn: {
          label: 'Activities.SMSInfo.ExecuteAddtoLink.Btn.Label',
        },
      },
      executeAddLinktoSMS: {
        btn: {
          label: 'Activities.SMSInfo.ExecuteAddLinktoSMS.Btn.Label',
        },
      },
      executeAddContracttoSMS: {
        btn: {
          label: 'Activities.SMSInfo.ExecuteAddContracttoSMS.Btn.Label',
        },
      },
      textHelp: 'Activities.SMSInfo.TextHelp',
      tooltip: 'Activities.SMSInfo.Tooltip',
    },
    exportList: {
      searchPlaceholder: 'Activities.ExportList.Search.Placeholder',
      exportItem: 'Activities.ExportList.Export',
      editModalDescription: 'Activities.ExportList.EditModal.Description',
      exportModalTitle: 'Activities.ExportList.ExportModal.Title',
      exportModalDescription: 'Activities.ExportList.ExportModal.Description',
    },
    pdfContract: {
      signedList: 'Activities.PdfContract.SignedList',
      signedListDescription: 'Activities.PdfContract.SignedList.Description',
      pdfFileTypeError: 'Activities.PdfContract.PdfFileTypeError',
      customFields: 'Activities.PdfContract.CustomFields',
      variousOptions: 'Activities.PdfContract.VariousOptions',
      uploadFontModalTitle: 'Activities.PdfContract.UploadFontModal.Title',
      uploadFontModalDescription: 'Activities.PdfContract.UploadFontModal.Description',
      fontFileTypeError: 'Activities.PdfContract.FontFileTypeError',
      showGuidelines: 'Activities.PdfContract.ShowGuidelines',
      textBeforeMergefield: 'Activities.PdfContract.Fields.TextBeforeMergefield',
      textAfterMergefield: 'Activities.PdfContract.Fields.TextAfterMergefield',
      removeMergeFields: 'Activities.PdfContract.Button.RemoveMergeFields',
      keepMergefieldsConfirm: 'Activities.PdfContract.KeepMergefieldsConfirm',
      buttonSettings: 'Activities.PdfContract.Tabs.ButtonSettings',
      signPageSettings: 'Activities.PdfContract.Tabs.SignPageSettings',
      fileNameError: 'Activities.PdfContract.FileName.Error',
      acceptButtonShow: 'Activities.PdfContract.AcceptButtonShow',
      acceptText: 'Activities.PdfContract.AcceptButtonText',
      acceptButtonDefaultText: 'Activities.PdfContract.AcceptButtonDefaultText',
      declineButtonShow: 'Activities.PdfContract.DeclineButtonShow',
      declineText: 'Activities.PdfContract.DeclineButtonText',
      declineButtonDefaultText: 'Activities.PdfContract.DeclineButtonDefaultText',
      helpButtonShow: 'Activities.PdfContract.HelpButtonShow',
      helpText: 'Activities.PdfContract.HelpButtonText',
      helpButtonDefaultText: 'Activities.PdfContract.HelpButtonDefaultText',
      signSecurityMfa: 'Activities.PdfContract.SignPageSettings.SignSecurityMfa',
      mfaTypeEmailHelpText: 'Activities.PdfContract.MfaType.EmailHelpText',
      mfaTypeEmail: 'Activities.PdfContract.MfaType.Email',
      mfaTypeSms: 'Activities.PdfContract.MfaType.Sms',
      mfaTypeEmailAndSms: 'Activities.PdfContract.MfaType.EmailAndSms',
      previewModalTitle: 'Activities.PdfContract.Preview.Modal.Title',
      previewModalDescription: 'Activities.PdfContract.Preview.Modal.Description',
      emailOrPhoneNumber: 'Activities.PdfContract.Preview.Modal.EmailOrPhoneNumber',
    },
  },
  leads: {
    lifeCycle: {
      tabsTitle: 'LeadsLifeCycle.tabsTitle',
      tabsSubTitle: 'LeadsLifeCycle.tabsSubTitle',
      leadsReleaseFromStatus: 'LeadsLifeCycle.leadsReleaseFromStatus',
      leadsLeftInStatus: 'LeadsLifeCycle.leadsLeftInStatus',
      lifecycleStatus: 'LeadsLifeCycle.LifecycleStatus',
      deleteData: 'LeadsLifeCycle.DeleteData',
    },
  },
  login: {
    loginButton: 'Login.Button',
    emailLabel: 'Login.Email.Label',
    passwordLabel: 'Login.Password.Label',
    loginErrorMessage: 'Login.Error.Message',
  },
};
