import {
  FC,
  useCallback,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ClButton,
  ClDropdown,
  ClDropdownItem,
  ClIcon,
  ClTextField,
} from '@enreach/core-component-library-react';
import classNames from 'classnames';
import { shallow } from 'zustand/shallow';

import Modal from '@components/Modal';

import { useStore } from '@store';
import useColorMode from '@store/useColorMode';
import useLocales, {
  Locale,
  useLocalesStore,
} from '@store/useLocales';

import useUserInfo from '@queries/useUserInfo';

import s from './TopMenu.module.scss';

const TopMenu: FC = () => {
  const [sideMenuOpen, set] = useStore(
    (state) => [state.sideMenuOpen, state.set],
    shallow,
  );
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [showUserSettings, setShowUserSettings] = useState(false);
  const setUser = useStore((state) => state.setUser);
  const setPageLoaderRef = useCallback(
    (node: HTMLDivElement) => {
      set({
        pageLoaderNode: node,
      });
    },
    [set],
  );
  const navigate = useNavigate();

  const [search, setSearch] = useState<string>('');

  const {
    general: {
      loading,
    },
    topMenu: {
      searchPlaceholder,
    },
  } = useLocales();

  const {
    locale,
    setLocale,
  } = useLocalesStore();

  const { data: userInfo, isLoading: isUserInfoLoading } = useUserInfo();

  const {
    colorMode,
    setColorMode,
  } = useColorMode();

  const handleChangeColorMode = () => {
    setColorMode(colorMode === 'light' ? 'dark' : 'light');
  };

  return (
    <div
      className={classNames(s.root, {
        [s.sideMenuOpen]: sideMenuOpen,
      })}
    >
      <div className={s.search}>
        <ClTextField
          className={s.searchField}
          type="text"
          placeholder={searchPlaceholder}
          value={search}
          onClInput={(e) => {
            setSearch(e.detail.value as string);
          }}
          variant="subtle"
        >
          <ClIcon name="action--search" slot="leading" />
        </ClTextField>
      </div>

      <div className={s.actions}>
        <div
          role="button"
          tabIndex={-1}
          className={s.button}
          onClick={handleChangeColorMode}
        >
          <ClIcon
            name={colorMode === 'light' ? 'view--dark' : 'view--light'}
          />
        </div>
        <div className={s.button}>
          <ClIcon
            name="communication--text--chat"
          />
        </div>
        <div className={s.button}>
          <ClIcon
            name="generic--info"
          />
        </div>
        <div className={classNames(s.button, s.userSettings)}>
          <ClButton
            iconName="generic--user--user"
            onClick={() => {
              setShowUserDropdown(true);
            }}
            variant="secondary"
            small
            className={s.userMenuButton}
          >
            <span>
              {isUserInfoLoading && (
                <span>
                  {loading}
                </span>
              )}
              {!isUserInfoLoading && userInfo && (
                <span>
                  {userInfo.userName}
                </span>
              )}
            </span>
          </ClButton>
          <ClDropdown
            open={showUserDropdown}
            variant="menu"
            onClCloseEnd={() => {
              setShowUserDropdown(false);
            }}
            fullWidth
            className={s.userDropdown}
          >
            <ClDropdownItem
              onClick={() => {
                setShowUserSettings(true);
                setShowUserDropdown(false);
              }}
              label="Settings"
              selected={false}
              value="Settings"
            >
              <ClIcon name="action--settings" slot="leading" />
            </ClDropdownItem>
            <ClDropdownItem
              onClick={() => {
                setUser({
                  token: null,
                });
                localStorage.removeItem('userToken');
                navigate('/Login', { replace: true });
              }}
              label="Logout"
              selected={false}
              value="Logout"
            >
              <ClIcon name="action--leave" slot="leading" />
            </ClDropdownItem>
          </ClDropdown>
        </div>
      </div>

      <Modal
        show={showUserSettings}
        header="User Settings"
        onToggle={setShowUserSettings}
      >
        <div slot="content">
          <select
            onChange={async (e) => {
              await setLocale(e.target.value as Locale);
            }}
            value={locale}
          >
            <option value={Locale.En}>English</option>
            <option value={Locale.Da}>Danish</option>
          </select>
        </div>
      </Modal>

      <div className={s.pageLoader} ref={setPageLoaderRef} />
    </div>
  );
};

export default TopMenu;
