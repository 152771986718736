import {
  FC, Fragment,
} from 'react';
import { Link } from 'react-router-dom';
import { ClIcon } from '@enreach/core-component-library-react';
import cn from 'classnames';

import s from './Breadcrumb.module.scss';

export interface Item {
  to?: string
  content: string
}

interface Props {
  items: Item[]
  className?: string
}

const Breadcrumb: FC<Props> = ({
  items,
  className,
}) => (
  <div className={cn(s.root, className)}>
    {items.map((o, i) => (
      <Fragment key={`${(o.to || '')}${o.content}`}>
        {o.to
          ? <Link to={o.to}>{o.content}</Link>
          : <span className={s.disabled}>{o.content}</span>}

        {i !== items.length - 1 && <ClIcon className={s.arrow} name="micro--chevron--right" />}
      </Fragment>
    ))}
  </div>
);

export default Breadcrumb;
