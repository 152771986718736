import {
  useEffect,
} from 'react';
import { Outlet } from 'react-router-dom';

import { useLocalesStore } from '@store/useLocales';

// load main app data like user settings and translations
export default function DataLayout() {
  const {
    locale,
    setLocale,
    loaded,
  } = useLocalesStore();

  useEffect(
    () => {
      // set locale from user settings

      setLocale(locale);
    },
    [locale, setLocale],
  );

  return (
    loaded
      ? <Outlet />
      : <div>Loading...</div>
  );
}
