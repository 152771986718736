import { useQuery } from '@tanstack/react-query';

import request from '@utils/request';

interface UserInfo {
  active: boolean;
  email: string;
  id: number;
  userName: string;
}
interface UserInfoResult {
  isSuccess: boolean;
  result: UserInfo;
}

export default function useUserInfo() {
  return useQuery({
    queryKey: ['userInfo'],
    async queryFn() {
      const { data } = await request.get<UserInfoResult>('/Account/GetUserInfo');
      return data.result;
    },
  });
}
