import {
  FC,
  useCallback,
  useState,
} from 'react';
import {
  FormProvider,
  useForm,
} from 'react-hook-form';
import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  ClButton,
  ClIcon,
  ClIconButton,
  ClLoader,
  ClToast,
} from '@enreach/core-component-library-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import TextField from '@components/Form/TextField';

import { useStore } from '@store';
import useColorMode from '@store/useColorMode';
import useLocales from '@store/useLocales';

import request from '@utils/request';

import s from './Login.module.scss';

interface LoginFormValues {
  email: string;
  password: string;
}

const schema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
}).required();

const Login: FC = () => {
  const navigate = useNavigate();

  const {
    general: {
      error,
    },
    login: {
      loginButton,
      emailLabel,
      passwordLabel,
      loginErrorMessage,
    },
  } = useLocales();

  const [loginError, setLoginError] = useState(false);

  const form = useForm<LoginFormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver<LoginFormValues>(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const {
    handleSubmit,
    getValues,
  } = form;

  const setUser = useStore((state) => state.setUser);
  const [isLoading, setIsLoading] = useState(false);
  const loaderValue = isLoading ? 0.75 : 0;
  const [searchParams] = useSearchParams();

  const login = useCallback(
    async () => {
      const userName = getValues('email');
      const password = getValues('password');
      setIsLoading(true);
      try {
        const result = await request.post<string>('/Auth/Token', {
          userName,
          password,
        });
        if (result.data) {
          setUser({
            token: result.data,
          });
          localStorage.setItem('userToken', result.data);
          navigate(searchParams.get('to') || '/ ', { replace: true });
        }
      } catch {
        setLoginError(true);
        setIsLoading(false);
      }
    },
    [getValues, navigate, searchParams, setUser],
  );

  const {
    colorMode,
    setColorMode,
  } = useColorMode();

  const handleChangeColorMode = () => {
    setColorMode(colorMode === 'light' ? 'dark' : 'light');
  };

  return (
    <div className={s.root}>
      <div className={s.left}>
        <FormProvider {...form}>
          <div className={s.logo}>
            <img src="/flows-enreach-logo.png" alt="Flows by Enreach" />
          </div>
          <form onSubmit={handleSubmit(login)} className={s.form}>
            <TextField<LoginFormValues>
              name="email"
              label={emailLabel}
              type="email"
              className={s.field}
            >
              <ClIcon
                name="communication--text--email"
                slot="leading"
              />
            </TextField>

            <TextField<LoginFormValues>
              name="password"
              label={passwordLabel}
              type="password"
              className={s.field}
            >
              <ClIcon
                name="action--lock--lock"
                slot="leading"
              />
            </TextField>

            <div className={s.actions}>
              <ClButton type="submit">{loginButton}</ClButton>
            </div>

            <div className={s.loader}>
              <ClLoader
                indeterminate={isLoading}
                value={loaderValue}
                thickness={10}
              />
            </div>
          </form>
        </FormProvider>

        <ClIconButton
          className={s.colorModeSwitch}
          iconName={colorMode === 'light' ? 'view--dark' : 'view--light'}
          onClick={handleChangeColorMode}
        />

        <ClToast
          text={loginErrorMessage}
          header={error}
          show={loginError}
          transition="fade"
          position="top"
          onExited={() => setLoginError(false)}
          variant="error"
        >
          <ClIcon name="notification--error" slot="icon" />
        </ClToast>
      </div>
      <div className={s.right} />
    </div>
  );
};

export default Login;
