import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useStore } from '@store';

const AuthLayout: FC = () => {
  const user = useStore((state) => state.user);

  return (
    user.token
      ? (
        <Navigate to="/" />
      )
      : (
        <Outlet />
      )
  );
};

export default AuthLayout;
