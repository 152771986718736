import {
  FC,
} from 'react';
import { Link } from 'react-router-dom';
import { ClTypography } from '@enreach/core-component-library-react';

import PageHeader from '@components/PageHeader';

import useLocales from '@store/useLocales';

import getRoutePage, {
  Pages,
  Sections,
} from '@utils/routes';

const Dashboard: FC = () => {
  const {
    pages,
  } = useLocales();
  return (
    <>
      <PageHeader
        section={Sections.Dashboard}
      />

      <ClTypography variant="h5">
        Ready pages:
      </ClTypography>

      <ul>
        <li>
          <Link to={getRoutePage(Sections.Activities, Pages.ExportList)}>
            {pages[Pages.ExportList]}
          </Link>
        </li>
        <li>
          <Link to={getRoutePage(Sections.Activities, Pages.Sms)}>
            {pages[Pages.Sms]}
          </Link>
        </li>
      </ul>
    </>
  );
};

export default Dashboard;
