import { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ClLoader } from '@enreach/core-component-library-react';

import { useStore } from '@store';

import s from './PageLoader.module.scss';

const PageLoader: FC = () => {
  const pageLoaderNode = useStore((state) => state.pageLoaderNode);

  const [show, setShow] = useState<boolean>(false);

  useEffect(
    () => {
      const timeout = window.setTimeout(
        () => {
          setShow(true);
        },
        150,
      );

      return () => {
        if (timeout) {
          window.clearTimeout(timeout);
        }
      };
    },
    [],
  );

  return (
    pageLoaderNode && show
      ? ReactDOM.createPortal(
        <ClLoader className={s.root} indeterminate thickness={4} />,
        pageLoaderNode,
      )
      : null
  );
};

export default PageLoader;
