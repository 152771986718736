import {
  ComponentProps,
  Dispatch,
  FC,
  useRef,
} from 'react';
import {
  ClDialog,
  ClLightbox,
} from '@enreach/core-component-library-react';

interface Props extends ComponentProps<typeof ClDialog> {
  show: boolean;
  onToggle: Dispatch<boolean>;
  closeOnClick?: boolean;
}

const Modal: FC<Props> = ({
  children,
  show,
  onToggle,
  onCloseEnd,
  closeOnClick = true,
  ...rest
}) => {
  const refDialog = useRef<HTMLClDialogElement>(null);

  return (
    <ClLightbox
      onCloseStart={() => onToggle(false)}
      // className={s.lightbox}
      open={show}
      closeOnClick={closeOnClick}
    >
      <ClDialog
        open={show}
        onCloseStart={() => onToggle(false)}
        ref={refDialog}
        {...rest}
      >
        {children}
      </ClDialog>
    </ClLightbox>
  );
};

export default Modal;
