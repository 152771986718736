import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';

import Activities from '@routes/Activities';
import Dashboard from '@routes/Dashboard';
import Login from '@routes/Login';
import NoMatch from '@routes/NoMatch';

import AuthLayout from '@components/AuthLayout';
import DataLayout from '@components/DataLayout';
import PageLayout from '@components/PageLayout';
import RequireAuth from '@components/RequireAuth';

import { Sections } from '@utils/routes';

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DataLayout />}>
          <Route path="/" element={<AuthLayout />}>
            <Route path={Sections.Login} element={<Login />} />
          </Route>
        </Route>

        <Route path="/" element={<DataLayout />}>
          <Route path="/" element={<RequireAuth />}>
            <Route path="/" element={<PageLayout />}>
              <Route index element={<Dashboard />} />
              <Route path={`${Sections.Activities}/*`} element={<Activities />} />
              <Route path="*" element={<NoMatch />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
