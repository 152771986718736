import {
  createSearchParams,
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom';

import { useStore } from '@store';

export default function RequireAuth() {
  const location = useLocation();
  const user = useStore((state) => state.user);

  const path = `/login?to=${createSearchParams({
    to: location.pathname,
  }).toString()}`;

  return (
    user.token

      ? <Outlet />
      : <Navigate to={path} state={{ from: location }} replace />
  );
}
