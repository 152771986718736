import {
  FC,
  ReactNode,
  useMemo,
} from 'react';
import { ClIcon } from '@enreach/core-component-library-react';
import cn from 'classnames';

import Breadcrumb, { Item as BreadcrumbItem } from '@components/Breadcrumb';

import useLocales from '@store/useLocales';

import getRoutePage, {
  getRouteSection,
  Pages,
  Sections,
  SubPages,
} from '@utils/routes';

import s from './PageHeader.module.scss';

interface PageHeaderProps {
  section: Sections;
  page?: Pages;
  subPage?: SubPages;
  title?: string;
  iconName?: string;
  children?: ReactNode;
}

const PageHeader: FC<PageHeaderProps> = ({
  section,
  page,
  subPage,
  title,
  iconName,
  children,
}) => {
  const {
    sections,
    pages,
    subPages,
  } = useLocales();

  const breadcrumbs = useMemo<BreadcrumbItem[]>(
    () => {
      const result: BreadcrumbItem[] = [
        {
          to: page ? getRouteSection(section) : undefined,
          content: sections[section],
        },
      ];

      if (page) {
        result.push({
          to: subPage ? getRoutePage(section, page) : undefined,
          content: pages[page],
        });

        if (subPage) {
          result.push({
            to: title ? getRoutePage(section, page, subPage) : undefined,
            content: subPages[subPage],
          });
        }
      }

      if (title) {
        result.push({
          content: title,
        });
      }

      return result;
    },
    [page, pages, section, sections, subPage, subPages, title],
  );

  const pageText = page && pages[page];
  const titleText = title || (subPage && subPages[subPage]);

  const info = (
    <>
      <Breadcrumb items={breadcrumbs} />

      <p className={s.title}>
        {iconName && (
          <ClIcon
            name={iconName}
            style={{
              marginRight: 'var(--spacing-1)',
            }}
          />
        )}
        {pageText}
        {titleText && (
          <>
            <ClIcon
              name="micro--chevron--right"
              style={{
                margin: '0 var(--spacing-1)',
              }}
            />
            {titleText}
          </>
        )}
      </p>
    </>
  );

  return (
    <div
      className={cn(s.root, {
        [s.withChildren]: children,
      })}
    >
      {children
        ? (
          <>
            <div className={s.info}>
              {info}
            </div>
            <div>{children}</div>
          </>
        )
        : info}
    </div>
  );
};

export default PageHeader;
