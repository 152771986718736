import { ColorMode } from '@enreach/brand-library-enreach/web';
import { createWithEqualityFn } from 'zustand/traditional';

import brandLibrary from '@utils/brandLibrary';

// Get the initial colorMode (dark/light)
function getInitialColorMode() {
  const mql = window.matchMedia('(prefers-color-scheme: dark)');
  const hasMediaQueryPreference = typeof mql.matches === 'boolean';

  if (hasMediaQueryPreference) {
    return mql.matches ? 'dark' : 'light';
  }

  return 'light';
}

const localStorageKey = 'colorMode';

function loadColorMode(): ColorMode {
  return localStorage.getItem(localStorageKey) as ColorMode ?? getInitialColorMode();
}

function saveColorMode(colorMode: ColorMode) {
  localStorage.setItem(localStorageKey, colorMode);
}

interface ColorModeStore {
  colorMode: ColorMode;
  setColorMode: (colorMode: ColorMode) => void;
}

const useColorMode = createWithEqualityFn<ColorModeStore>((set) => ({
  colorMode: loadColorMode(),
  setColorMode: (colorMode: ColorMode) => {
    brandLibrary.setBrand(colorMode);
    saveColorMode(colorMode);
    set({
      colorMode,
    });
  },
}));

export default useColorMode;
