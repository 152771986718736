import '@utils/setupCcl';
import '@utils/brandLibrary';

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import AppRoutes from '@routes';

import useColorMode from '@store/useColorMode';

import './static/styles/index.scss';

if (import.meta.hot) {
  import.meta.hot.on(
    'vite:beforeUpdate',
    /* eslint-disable-next-line no-console */
    () => console.clear(),
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      // staleTime: 60 * 60 * 1000,
      // cacheTime: 60 * 60 * 1000,
    },
  },
});

// setup store with initial color mode
useColorMode.getState().setColorMode(useColorMode.getState().colorMode);

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppRoutes />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
